@import "../../colors";
@import "../../variables";

.CaseEnterHeader {
  margin: 0 0 30px 0;

  @media (max-width: $carouselMaxWidth) {
    margin: 0;
  }

  &__description {
    white-space: pre-line;
    padding: 16px 40px;

    line-height: 22px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;

    @media (max-width: $carouselMaxWidth) {
      font-size: 14px;
    }

    color: $welcomeDescriptionColor;
    background: $welcomeDescriptionBackgroundColor;

    &__content {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
}