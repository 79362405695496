@import "../../colors";
@import "../../variables";

.Policies {
  background-color: $policiesBackgroundColor;
  padding: 5px 0 10px 0;

  &__header {
    text-align: center;
    margin: 16px 0 12px 0;
    font-weight: bolder;

    font-size: 20px;

    @media (max-width: $carouselMaxWidth) {
      font-size: 16px;
    }
  }

  &__cards {
    display: flex;
    max-width: 1600px;
    justify-content: space-around;
    margin: auto;

    @media (max-width: 1024px) {
      flex-wrap: wrap;
    }
  }

  a {
    color: $policiesInstructionLinkColor;
  }
}