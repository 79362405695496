@import "../../colors";

.CaseNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  text-align: center;

  &__image {
    padding: 15px 0 0 0;
  }

  &__header {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    color: $notFoundHeaderColor;
    padding: 20px 40px;
  }

  &__description {
    padding: 0 20px 30px 20px;
  }
}