.SwitchToMobileButton {
  margin: 0 10px -7px 10px;
  border: 0;
  padding: 0;

  &__icon {
      width: 40px;
  }
}

.SwitchToMobileButtonModal {
  .ant-modal-content {
    padding: 50px 60px 0 60px;
  }
}