@import "../../colors";
@import "../../variables";

.Language {
  color: $languageColor;
  margin: 0 10px;

  @media (max-width: $carouselMaxWidth) {
    margin: 0 4px;
  }

  &__content {
    display: flex;
    gap: 4px;
  }
}