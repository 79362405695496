@import "../../colors";
@import "../../variables";

.Section {

  &__header {
    padding: 20px var(--sidePadding) 1px var(--sidePadding);
    background-color: $descriptionsBackgroundColor;

    &__title {
      color: $descriptionsText;
      font-weight: bold;

      font-size: 18px;

      @media (max-width: $carouselMaxWidth) {
        font-size: 16px;
      }
    }

    .ant-divider-horizontal {
      margin: 1px 0;
    }
  }
}