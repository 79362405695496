.UploadStep {
  overflow-x: hidden;

  .InstructionGroup__instruction {
    .LoadingMediaElement {
      width: 98%;
    }
  }

  &__numberOfMedia {
    font-weight: 700;
    text-align: center;
    padding: 0 var(--sidePadding) 0 var(--sidePadding);

    &__comment {
      font-style: italic;
      font-weight: 400;
      margin: 5px 0 0 0;
    }
  }

  &__upload {
    text-align: center;
    margin: 20px 0 20px 0;
  }
}