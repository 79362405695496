@import "../../colors";
@import "../../variables";

.MailToDpoc {
  margin-top: 40px;
  max-width: 600px;
  font-size: 16px;

  @media (max-width: $carouselMaxWidth) {
    text-align: center;
  }

  &__link {
    white-space: pre;
  }
}