@import "../../colors";
@import "../../variables";

.ReportedMedia {
  margin: 40px 0;
  text-align: center;

  &__icon {
    margin: 0 0 24px 0;

    svg {
      width: 72px;
      height: 72px;
      fill: $success;

      @media (max-width: $carouselMaxWidth) {
        width: 36px;
        height: 36px;
      }
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
}