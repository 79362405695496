.MediaCarousel {
  padding: 25px var(--sidePadding) 0 var(--sidePadding);

  .ant-carousel .slick-list {
    overflow: visible;

    .slick-cloned {
      visibility: hidden;
    }
  }

  margin: 0 var(--mediaCarousel-sideMargin);
  display: var(--list--display);
  justify-content: center;

  &--noCarousel {
    display: flex;
    flex-wrap: wrap;
  }

  .MediaWrapper {
    position: relative;
    justify-content: center;
    display: table;
    margin: var(--imageWrapper--margin);
    width: var(--imageWrapper--width);
  }
}