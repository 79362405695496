@import "../../colors";

.CaseIdHeader {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;

  &__icon {
    color: $dpocIdIconColor;
    padding: 5px;
  }
}