@import "../../colors";
@import "../../variables";

.TicketInformationBody {
  padding: 20px var(--sidePadding) 0 var(--sidePadding);

  &__content {
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 15px 0;
    text-align: var(--long--text--aling);

    font-size: 20px;

    @media (max-width: $carouselMaxWidth) {
      font-size: 16px;
    }
  }

  &__timeout {
    color: $timeOutColor;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
    margin: 0 0 28px 0;
    text-decoration: underline;
  }
}