@import "../../variables";

.ExtraStep {
  &__limit {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.2px;
    padding: 0 var(--sidePadding) 0 var(--sidePadding);
    white-space: pre-wrap;

    @media (max-width: $carouselMaxWidth) {
      font-size: 18px;
    }
  }

  &__upload {
    text-align: center;
    margin: 20px 0 20px 0;
  }

  &__description {
    margin: 0 0 30px 0;
    padding: 20px var(--sidePadding) 0 var(--sidePadding);

    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.2px;
  }
}