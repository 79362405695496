.CenteredSpin {
  margin: 100px auto;
  display: block;

  &--default {
    margin: 60px;
  }

  &--small {
    margin: 30px;
  }
}