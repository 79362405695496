@import "../../colors";
@import "../../variables";

.FaqMenu {
  &__title {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }

  &__menu {
    border: 0 !important;
    margin: 30px 0 0 0;

    &__rootItem, &__childItem {
      font-size: 16px;
      height: auto !important;
      margin: 0 !important;

      .ant-menu-title-content, .ant-menu-submenu-title {
        line-height: 24px !important;
        white-space: normal !important;
        height: auto !important;
      }

      .ant-menu-title-content {
        padding: 12px 16px 12px 14px !important;

        @media (max-width: $carouselMaxWidth) {
          padding: 8px 6px 8px 4px !important;
        }
      }
    }

    &__rootItem {
      border-bottom: 1px solid $faqItemDividerColor;
    }
  }
}