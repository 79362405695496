@import "../../colors";
@import "../../variables";

.CountryByLocation {
  border: 2px $countryLocationBorder solid;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 $countryLocationBorderShadow;

  margin: 10px var(--sidePadding) 16px var(--sidePadding);
  padding: 20px 10px;

  @media (max-width: $countrySelectionMobileMaxWidth) {
    border-width: 1px;
  }

  &__header {
    font-size: 16px;
    padding: 0 37px;
    line-height: 24px;

    @media (max-width: $countrySelectionMobileMaxWidth) {
      font-size: 14px;
      padding: 1px 17px 0 17px;
      line-height: 22px;
    }
  }

  &__select {
    display: flex;
    align-items: baseline;

    @media (max-width: $countrySelectionMobileMaxWidth) {
      flex-direction: column;
      align-items: center;
    }

    &__country {
      margin: 20px 72px 14px 32px;

      .anticon svg {
        width: 24px;
        height: 24px;
      }

      @media (max-width: $countrySelectionMobileMaxWidth) {
        margin: 17px 0 0 0;
        flex-direction: column;
      }
      white-space: pre;

      &__name {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        padding-left: 18px;

        @media (max-width: $countrySelectionMobileMaxWidth) {
          font-size: 18px;
          padding-left: 6px;
        }
      }
    }

    &__languages {
      display: flex;
      gap: 15px 30px;
      line-height: 22px;
      flex-wrap: wrap;

      @media (max-width: $countrySelectionMobileMaxWidth) {
        margin: 24px 0 0 0;
        flex-wrap: wrap;
        gap: 15px 10%;
        width: 70%;
      }

      &__language {
        color: $countryLocaleColor;
        box-sizing: border-box;
        font-size: 18px;
        @media (max-width: $countrySelectionMobileMaxWidth) {

          width: 40%;
          &:nth-child(even) {
            text-align: right;
          }
        }
      }
    }
  }
}