.Loading {
  display: flex;
  align-items: center;
  min-height: 400px;

  &__content {
    width: 100%;
    display: block;

    .ant-spin-text {
      margin-top: 6px;
    }
  }
}