@import "../../colors";
@import "../../variables";

.FaqDetail {
  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin: 0 0 30px 0;
  }

  &__description {
    font-size: 16px;
    line-height: 22px;
    max-width: 650px;
    padding: 0 0 20px 0;
  }

  &__report {
    margin: 20px 0;

    @media (max-width: $carouselMaxWidth) {
      margin: 0 0 20px 0;
      width: 100%;
    }
  }

  &__divider {
    margin: 20px 0;
    border-bottom: 1px solid $headerBorderColor;
  }
}