@import "../../colors";

.UploadedMediaSummaryHeader {

  &--large {
    .UploadedMediaSummaryHeader {
      &__empty {
        font-size: 20px;
      }

      &__titleRow {
        &__title {
          font-size: 24px;
          &--empty {
            font-size: 20px;
          }
        }

        &__count {
          font-size: 20px;
        }
      }
    }
  }

  &--empty {
    max-width: 700px;
    margin: 0 auto;
  }

  &__empty {
    margin: 8px 0 0 0;
    font-size: 14px;
    font-style: italic;
    line-height: normal;
    font-weight: 700;
  }

  &__titleRow {
    display: flex;
    justify-content: space-between;

    &__title {
      font-weight: 700;
      line-height: 32px;

      &--empty {
        line-height: 22px;
        color: $emptyPhotosHeaderColor;
      }
    }

    &__count {
      color: $colorTextLabel;
    }
  }
}