@import "../../colors";
@import "../../variables";

.ApplicationTitle {
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  padding: 10px 15px;
  font-size: 30px;
  color: $titleTextColor;

  @media (max-width: $carouselMaxWidth) {
    font-size: 20px;
  }
}