$carouselMaxWidth: 540px;  // Keep in sync with SJ const CAROUSEL_MAX_SCREEN_WIDTH
$countrySelectionMobileMaxWidth: 700px;

:root {
  --sidePadding: 60px;
  --mediaCarousel--sideMargin: 8px;
  --maxFooterWidth: 400px;
  --instructions--width: min(420px, calc(100vw / 2 - var(--sidePadding) - var(--mediaCarousel--sideMargin)));
  --list--display: flex;
  --imageWrapper--margin: unset;
  --imageWrapper--width: unset;
  --long--text--aling: center;
  --step--header--size: 30px;
  --step--header--weight: 700;
  --step--header--align: center;
}

@media (max-width: $carouselMaxWidth) {
  :root {
    --imageWrapper--margin: 0 auto;
    --imageWrapper--width: calc(100vw - 70px);
    --sidePadding: 25px;
    --mediaCarousel--sideMargin: 8px;
    --maxPageWidth: unset;
    --list--display: block;
    --instructions--width: unset;
    --long--text--aling: left;
    --step--header--size: 20px;
    --step--header--weight: 400;
    --step--header--align: left;
  }
}