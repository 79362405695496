@import "../../colors";
@import "../../variables";

.ConfirmationHeader {
  text-align: center;
  padding: 18px 50px 38px 50px;
  max-width: 560px;
  margin: 0 auto;

  &__icon {
    margin: 0 0 24px 0;
    svg {
      width: 72px;
      height: 72px;
      fill: $success;
    }
  }

  &__title {
    font-weight: 700;
    line-height: 32px;

    font-size: 30px;
    margin: 10px 0 26px 0;

    @media (max-width: $carouselMaxWidth) {
      margin: unset;
      font-size: 24px;
    }
  }

  &__description {
    margin: 0 0 37px 0;
    font-size: 20px;
    line-height: 28px;
  }
}