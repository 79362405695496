@import "../../colors";

.FooterButtons {
  padding: 11px var(--sidePadding) 19px var(--sidePadding);
  position: sticky;
  bottom: 0;
  background-color: $backgroundColor;
  border-top: 1px solid $footerBorder;
  z-index: 1000;

  &__content {
    max-width: var(--maxFooterWidth);
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    & > *:last-child:first-child {
      margin-left: auto;
    }
  }
}