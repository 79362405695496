@import "../colors";
@import "../variables";

.TermsAndConditionsPage {
  &__header {
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    margin: 25px var(--sidePadding);

    font-size: 30px;

    @media (max-width: $carouselMaxWidth) {
      font-size: 20px;
    }
  }

  &__info {
    color: $termsInfoColor;
    background: $termsInfoBackground;

    padding: 34px var(--sidePadding);
    @media (max-width: $carouselMaxWidth) {
      padding: 10px var(--sidePadding);
    }

    &__content {
      margin: 0 auto;
      max-width: 920px;

      font-weight: 700;
      line-height: 22px;
      text-align: center;
      font-size: 20px;

      @media (max-width: $carouselMaxWidth) {
        font-size: 14px;
      }
    }
  }

  &__content {
    padding: 30px var(--sidePadding);
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__description {
    padding: 10px var(--sidePadding) 16px var(--sidePadding);
    font-style: italic;
    font-weight: 700;
    line-height: 22px;
    max-width: 1010px;
    margin: 0 auto;
    text-align: center;
  }
}