@import "../../colors";
@import "../../variables";

.FaqHelpful {
  margin: 25px 0 0 0;

  @media (max-width: $carouselMaxWidth) {
    text-align: center;
  }

  &__title {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  &__actions {
    margin: 20px 10px;

    &__action {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin: 0 8px;
    }
  }
}