@import "../../colors";

.CaseIdTooltip {
  display: flex;
  padding: 4px 10px;

  &__icon {
    svg {
      color: $infoIconColor;
      width: 21px;
      height: 21px;
    }
  }

  &__content {
    padding: 8px 16px 8px 16px;
    gap: 8px;
    max-width: 220px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
}