@import "../../colors";

.StepHeader {
  display: flex;
  max-width: 700px;
  padding: 15px var(--sidePadding) 15px var(--sidePadding);
  margin: 0 auto;
  align-items: center;

  &__title {
    font-size: var(--step--header--size);
    font-weight: var(--step--header--weight);
    max-width: 700px;
  }

  &__description {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin-top: 20px;
  }
}
