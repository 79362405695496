.AgreeTermsForm {
  &__agreed {
    text-align: center;
  }

  &__submit {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 10px auto 0 auto;
    width: 100%;
    max-width: 320px;
    padding: 20px 16px;

    &__icon {
      display: block;
      position: relative;
      right: 0;
    }
  }

  &__error {
    text-align: center;
    font-style: italic;
    font-weight: 700;
    line-height: 22px;
    padding: 0 40px 15px 40px;
  }
}