@import "../../colors";

.TermsCheckbox {
  &__link {
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    text-decoration: underline;
    color: $textColor;
  }
}