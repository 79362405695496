@import "../../colors";
@import "../../variables";

.HelpButton {
  &.ant-btn {
    height: 32px !important;
    width: 32px !important;
    font-size: 16px !important;
    margin: 0 10px 0 10px;

    @media (max-width: $carouselMaxWidth) {
      height: 24px !important;
      width: 24px !important;
      font-size: 14px !important;
      margin: 0 5px 0 5px;
    }
  }
}

.HelpButtonModal {
  top: 65px;
  margin: 0;
  max-width: 100%;

  &__back {
    color: $helpBackColor;
  }

  &__content {
    padding: 80px 250px 60px 250px;

    @media (max-width: $carouselMaxWidth) {
      padding: 10px 10px 60px 10px;
    }
  }
}