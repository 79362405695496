@import "../colors";
@import "../variables";

.Page {
  font-family: Invention, serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  min-height: 100vh;
  background-color: $pageBackgroundColor;
  font-size: 16px;



  @media (max-width: $carouselMaxWidth) {
    font-size: 14px;
  }

  .ant-form label, .ant-form-item .ant-form-item-explain, .ant-btn  {
    font-size: 16px;

    @media (max-width: $carouselMaxWidth) {
      font-size: 14px;
    }
  }

  .ant-btn {
    height: 40px;

    @media (max-width: $carouselMaxWidth) {
      height: 32px;
    }
  }

  &__header {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px 0 5px;
    background-color: $headerBackground;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $headerBorderColor;
    max-width: var(--maxPageWidth);

    &__title {
      display: flex;
      height: 64px;
      align-items: center;

      &__pdrs {
        font-size: 17px;
        height: 19px;
        margin: 0 0 10px 25px;
      }

      &__anthemLogo {
        margin: 0 0 0 calc(var(--sidePadding) - 20px);
        max-height: 64px;
      }

      &__appVersion {
        margin: 0 0 4px 2px;
      }
    }

    &__controls {
      display: flex;
      align-items: center;

    }
  }

  &__content {
    width: 100%;
    background-color: $backgroundColor;
    max-width: var(--maxPageWidth);
  }

  .ant-carousel .slick-dots li button {
    background-color: $carouselDot;
    border: 1px solid $carouselDotBorder;
    opacity: 1;
    border-radius: 50%;
    height: 13px;
    width: 13px;
    margin-top: 3px;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background-color: $carouselDotActive;
    opacity: 1;
    height: 17px;
    width: 17px;
    margin-top: 0;
  }
}

.ant-select-item-option-content {
  a {
    text-decoration: none;
  }
}