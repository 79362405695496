.InstructionsContent {
  overflow-x: hidden;

  .InstructionMedia {
    .LoadingMediaElement {
      width: 98%;
    }
  }

  &__header {
    padding: 25px var(--sidePadding) 0 var(--sidePadding);
    font-size: var(--step--header--size);
    font-weight: var(--step--header--weight);
    text-align: var(--step--header--align);
    max-width: 700px;
    margin: auto;
  }

  &__title {
    text-align: center;
    margin: 0 0 20px 0;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
  }

  &__instructions {
    padding: 20px 0;
  }
}