@import "../../colors";

.SwitchToMobile {
  display: flex;
  padding: 5px 5px 30px 5px;

  justify-content: center;
  gap: 80px;
  width: 100%;
  box-sizing: border-box;

  color: $switchColor;

  &--background {
    background-color: $switchBackground;
  }

  &__body {

    &__header {
      margin: 10px 0 35px 0;
      text-align: center;
      font-size: 30px;
      font-weight: 700;
      line-height: 38px;
    }

    &__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }

    &__step, a {
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;

      &__link {
        text-decoration: underline;
        white-space: pre;
      }
    }
  }

  &__qr {
    background-color: $switchQRBackground;
    padding: 20px 33px;
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    align-items: center;
    border-radius: 5px;

    &__code {
      background-color: $backgroundColor;
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: content;
      align-self: center;
    }

    &__title {
      color: $switchQRColor;
      max-width: 215px;
      text-align: center;
      margin: 20px 0 0 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }
  }

}