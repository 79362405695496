@import "../../variables";

.MultipleUnitsDescription {
  text-align: center;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.2px;
  padding: 20px var(--sidePadding) 24px var(--sidePadding);
  white-space: pre-wrap;
  font-size: 24px;

  @media (max-width: $carouselMaxWidth) {
    padding: 0 var(--sidePadding) 12px var(--sidePadding);
    font-size: 18px;
  }
}