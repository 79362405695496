@import "../../colors";
@import "../../variables";

.InstructionMedia {
    margin: 0 0 25px 0;
    display: flex;
    flex-direction: column;
    width: var(--instructions--width);

    .LoadingMediaElement {
      min-height: 150px;
    }

    &__imageWrapper {

      &__exampleLabel {
        position: absolute;
        top: 10px;
        right: 15px;
        font-size: 12px;
        line-height: 20px;
        background-color: $imageExampleBackgroundColor;
        color: $imageExampleLabelColor;
        border: 1px solid $imageExampleBorderColor;
        pointer-events: none;
        padding: 1px 8px;
        border-radius: 2px;
        gap: 10px
      }
    }

    &__header {
      display: flex;
      align-items: baseline;
      margin: 8px 14px 0 14px;
      gap: 8px;
      bottom: 0;

      font-size: 18px;

      @media (max-width: $carouselMaxWidth) {
        font-size: 14px;
      }

      &__text {
        padding-bottom: 2px;
        max-width: min(300px, calc(100vw - 120px));

        &--do {
          color: $doColor;
        }

        &--dont {
          color: $dontColor;
        }
      }
    }
}