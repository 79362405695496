@import "../../colors";
@import "../../variables";

.CountrySelector {

  margin: 0 0 20px 0;

  $color: $generalTextColor;

  &__header {
    margin: 0 0 25px 102px;
    color: $titleTextColor;

    font-size: 20px;
    font-weight: 700;
    line-height: 28px;


    @media (max-width: $countrySelectionMobileMaxWidth) {
      margin: 0 0 16px 18px;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    &__icon {
      color: $questionIconText;
      padding: 5px;
    }
  }

  &__selectListHeader {
    margin: 40px var(--sidePadding) 0 calc(var(--sidePadding) + 40px);

    @media (max-width: $countrySelectionMobileMaxWidth) {
      margin: 25px var(--sidePadding) 0 var(--sidePadding);
      text-align: center;
    }
  }

  &__headerDivider {
    @media (max-width: $countrySelectionMobileMaxWidth) {
      margin-top: 5px;
    }
  }

  &__noCountryDetectedHeader {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    margin: -10px 0 0 0;

    @media (max-width: $countrySelectionMobileMaxWidth) {
      margin: -20px 0 0 0;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__noCountryDetectedSubHeader {
    display: flex;
    gap: 12px;
    align-items: baseline;

    margin: 50px var(--sidePadding) 30px calc(var(--sidePadding) + 40px);

    @media (max-width: $countrySelectionMobileMaxWidth) {
      margin: 30px var(--sidePadding) 20px calc(var(--sidePadding));
      gap: 8px;
    }

  }
}