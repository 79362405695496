@import "../../colors";
@import "../../variables";

.SwitchToShort {
  margin: 40px 0 80px 0;
  padding: 30px 10px 15px 10px;
  display: flex;
  border-top: 1px solid $headerBorderColor;

  &__icon {
    display: block;
    width: 65px;
    margin-top: 8px;

    &__image {
      width: 40px;
    }
  }

  &__instructions {

    &__title {
      font-size: 16px;
      font-weight: 700;
      line-height: 29px;
    }

    &__steps {
      padding: 0 0 0 20px;
      margin: 0;

      &__step {
        font-size: 16px;
        line-height: 29px;

        &__link {
          font-weight: 700;
          white-space: pre;
        }
      }
    }
  }
}