@import "../../colors";
@import "../../variables";

.HelpIntro {

  &__title {
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    margin: 0 0 40px 0;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 20px 0;
  }

  &__link {
    font-weight: 700;
  }
}