.MultipleUnitsInfoPage {

  &__content {
    &__header {
      padding: 25px var(--sidePadding) 0 var(--sidePadding);
      font-size: var(--step--header--size);
      font-weight: var(--step--header--weight);
      text-align: var(--step--header--align);
      max-width: 700px;
      margin: auto;
    }

    &__description {
      padding: 55px var(--sidePadding) 50px var(--sidePadding);
      margin: auto;
      max-width: 800px;

      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;

    }
  }
}