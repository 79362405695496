.UploadedMediaSummaryMedia {
    margin: 14px 0 10px 0;
    display: flex;
    gap: 22px;
    flex-wrap: wrap;

    .MediaElement {
      max-height: 59px;
      width: auto;
    }
}