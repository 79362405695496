@import "../../colors";

.MediaDragger {
  margin: auto;
  display: block;
  text-align: center;
  width: 480px;

  &__icon {
    color: $dragIconColor;

    svg {
      height: 48px;
      width: 48px;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 24px;
    margin: 10px 0 6px 0;
  }

  &__description {
    font-size: 14px;
    line-height: 22px;
    color: $dragDescriptionColor;
    margin: 6px 0 10px 0;
  }
}