@import "../../colors";

.PolicyCard {
  display: flex;
  margin: 10px 15px;
  padding: 24px;
  gap: 16px;
  max-width: 700px;

  background: $policyCardBackground;
  align-items: flex-start;
  box-shadow: 0 6px 16px 0 #00000014;

  &__icon {
    svg {
      color: $infoIconColor;
      width: 21px;
      height: 21px;
    }
  }

  &__content {

    &__header {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin: 0 0 10px 0;
    }

    &__description {
      white-space: pre-line;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }
}